import React, { Component } from "react";
import { db } from "../Firebase/firebase";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import { withRouter } from "react-router-dom";
import { AlertCircle } from "react-feather";
import moment from "moment";
import { Link } from "react-router-dom";

const config = "TCS";

class EditNew extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      mobile: "",
      email: "",
      pax: "",
      date: "",
      time: moment(),
      seatPref: "",
      contactPref: "mobile",
      remarks: "",
      loading: "Submit",
      checked: false,
      disabled: false,
      paxLimit: 10,
      sameDay: true,
      notices: [],
      hour: 0,
      hours: [],
      minutes: [],
      disabledDays: [],
      timeStep: 15,
      tabIndex: 1,
      hasChosen: true,
      defaultSlots: [],
      timeChosen: true,
      ht: "No",
      timingsToUse: { filterRanges: [], blockedRanges: [] },
      defaultTime: moment(),
      dataLoaded: false,
      data2Loaded: false,
    };
    this.onTimeChange = this.onTimeChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  isTimeBetween = (startTime, endTime, serverTime) => {
    let start = moment(startTime, "H:mm");
    let end = moment(endTime, "H:mm");
    let server = moment(serverTime, "H:mm");
    if (end < start) {
      return (
        (server >= start && server <= moment("23:59:59", "h:mm:ss")) ||
        (server >= moment("0:00:00", "h:mm:ss") && server < end)
      );
    }
    return server >= start && server < end;
  };

  componentDidMount() {
    let defaultWeekEndDoc =
      config === "TCS" ? "defaultTCSWeekEnd" : "defaultWeekEndCMPB";

    db.collection("timings")
      .doc(defaultWeekEndDoc)
      .onSnapshot((doc) => {
        let data = doc.data();
        data.start = moment(data.start.toDate());
        data.end = moment(data.end.toDate());
        data.blockedRanges = data.blockedRanges.map((range) => {
          return { timing: moment(range.timing.toDate()), label: range.label };
        });
        data.filterRanges = data.filterRanges.map((range) => {
          return {
            start: moment(range.start.toDate()),
            end: moment(range.end.toDate()),
          };
        });
        this.setState(
          {
            defaultWeekEnd: data,
          },
          () => {
            console.log(this.state.defaultWeekEnd);
            this.setState(
              {
                dataLoaded: true,
                timingsToUse: {
                  start: this.state.defaultWeekEnd.start,
                  end: this.state.defaultWeekEnd.end.hour(21),
                  timeStep: this.state.defaultWeekEnd.timeStep,
                  filterRanges: [
                    {
                      start: moment().hour(8).minute(0),
                      end: moment().hour(22).minute(0),
                    },
                  ],
                  blockedRanges: [],
                },
              },
              () => {
                if (this.props.match.params.reserve) {
                  db.collection("reservations")
                    .doc(this.props.match.params.reserve)
                    .get()
                    .then((doc) => {
                      this.setState(
                        {
                          ...doc.data(),
                        },
                        () => {
                          let timestring = this.state.time;
                          timestring = timestring.split(":");
                          console.log(timestring);
                          this.setState(
                            {
                              date: this.state.date.toDate(),
                              defaultTime: this.state.defaultTime.set({
                                hour: timestring[0],
                                minute: timestring[1],
                              }),
                            },
                            () => {
                              let chosenIndex =
                                this.state.defaultWeekEnd.filterRanges.findIndex(
                                  (item) => {
                                    let startTime =
                                      item.start.hour() * 100 +
                                      item.start.minute();
                                    let endTime =
                                      item.end.hour() * 100 + item.end.minute();

                                    let selectedTime =
                                      this.state.defaultTime.hour() * 100 +
                                      this.state.defaultTime.minute();

                                    return (
                                      selectedTime > startTime &&
                                      selectedTime < endTime
                                    );
                                  }
                                );
                              this.setState({
                                time: this.state.defaultTime,
                                data2Loaded: true,
                                tabIndex: chosenIndex,
                              });
                            }
                          );
                        }
                      );
                    });
                }
              }
            );
          }
        );
      });
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onTimeChange(value) {
    // do something
    this.setState({ time: value, hour: value.hour() }, () => {
      let minutes = [];
      let hourSelected = this.state.minutes.find((item) => {
        return item.value === this.state.hour;
      });
      minutes = hourSelected.minutes.map((item) => {
        return item.value;
      });
      this.setState({ blockedMinutes: minutes });
    });
  }

  onDateChange = (jsDate, dateString) => {
    this.setState({
      date: dateString.slice(0, 10),
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    if (!this.state.timeChosen) {
      alert("Please select a time slot!");
      return;
    }
    if (
      this.state.date == "" ||
      this.state.email == "" ||
      this.state.name == "" ||
      this.state.pax == "" ||
      this.state.mobile == "" ||
      this.state.seatPref == ""
    ) {
      this.setState({
        checked: true,
      });
      alert("Please fill in the required details!");
      return;
    } else if (this.state.date === "") {
      alert("Please select a date!");
      return;
    } else if (
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        this.state.email
      ) === false
    ) {
      alert("Please enter a valid email address.");
      return;
    } else {
      this.setState({
        loading: "Submitting...",
        disabled: true,
      });
      let newReserve = {
        name: this.state.name,
        mobile: this.state.mobile,
        email: this.state.email,
        pax: this.state.pax,
        date: new Date(this.state.date),
        time: this.state.time.format("HH:mm"),
        seatPref: this.state.seatPref,
        contactPref: this.state.contactPref,
        remarks: this.state.remarks,
        ht: this.state.ht,
        editedAt: new Date(),
        error: "",
      };

      db.collection("reservations")
        .doc(this.props.match.params.reserve)
        .update(newReserve);
      this.props.history.goBack();
    }
  };

  handleDayClick = (day, { selected, disabled }) => {
    if (disabled) {
      return;
    }
    let dayOfWeek = day.getDay();
    let whichDefault = this.state.defaultWeekEnd;

    this.setState({
      timingsToUse: {
        start: whichDefault.start,
        end: whichDefault.end,
        timeStep: whichDefault.timeStep,
        filterRanges: whichDefault.filterRanges,
        blockedRanges: [],
      },
    });
    this.setState({
      date: day,
      dayOfWeek,
    });
  };

  disabledHours(daySelect) {
    let day = daySelect.getDay();
    let month = daySelect.getMonth();
    let date = daySelect.getDate();
    if (month === 11 && date == 29) {
      return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 18, 19, 20, 21, 22, 23];
    } else {
      return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 22, 23];
    }
  }

  generateSlots = (availableRange) => {
    let { timeStep } = this.state.timingsToUse;
    let slots = [];
    let currentSlot = {
      timing: moment(availableRange.start),
      label: `${moment(availableRange.start).format("HH:mm")}`,
    };
    while (
      currentSlot.timing.hour() * 100 + currentSlot.timing.minute() <=
      moment(availableRange.end).hour() * 100 +
        moment(availableRange.end).minute()
    ) {
      let newSlot = { ...currentSlot };
      slots.push(newSlot);
      let nextSlot = { ...newSlot };
      nextSlot.timing = nextSlot.timing.clone().add(timeStep, "m");
      nextSlot.label = `${nextSlot.timing.format("HH:mm")}`;
      currentSlot = nextSlot;
    }
    return slots;
  };

  filterResults = (rangeIndex) => {
    let filteredSlots = this.generateSlots({
      start: this.state.timingsToUse.start,
      end: this.state.timingsToUse.end,
    });
    let rangeToUse = this.state.timingsToUse.filterRanges[rangeIndex];

    filteredSlots = filteredSlots.filter((slot) => {
      return (
        slot.timing.hour() * 100 + slot.timing.minute() <=
          rangeToUse.end.hour() * 100 + rangeToUse.end.minute() &&
        slot.timing.hour() * 100 + slot.timing.minute() >=
          rangeToUse.start.hour() * 100 + rangeToUse.start.minute()
      );
    });
    return filteredSlots;
  };

  disabledMinutes(hour) {
    let { date } = this.state;
    let dayOfWeek = date.getDay();
    if (hour === 10 && !(dayOfWeek === 0 || dayOfWeek === 6)) {
      return [0, 15, ...this.state.blockedMinutes];
    } else return this.state.blockedMinutes;
  }
  render() {
    let today = new Date();
    let hour = today.getHours();
    if (hour >= 20) {
      today.setDate(today.getDate() + 1);
    }
    today.setDate(today.getDate());

    return (
      <div id="create">
        <form>
          <div className="date-time full-width">
            {" "}
            <div className="form-group">
              <DayPicker
                onDayClick={this.handleDayClick}
                selectedDays={this.state.date}
                onDayChange={(day) => console.log(day)}
                showOverlay={true}
                disabledDays={[{ before: today }, ...this.state.disabledDays]}
              />
              {/*               <span>
                <br />
                *Dates that are greyed out are no longer available for
                reservations. Please walk in instead. We have reserved 40% of
                our tables for our walk-in diners.
              </span> */}
            </div>
            {this.state.date !== "" ? (
              <div className="form-group">
                <label
                  className="time-label"
                  style={
                    this.state.checked && this.state.time == ""
                      ? { color: "red" }
                      : null
                  }>
                  Time*
                </label>
                {this.state.dataLoaded && this.state.data2Loaded ? (
                  <div className="time-picker">
                    <div className="range-buttons">
                      {this.state.timingsToUse.filterRanges.map(
                        (range, index) => {
                          return (
                            <button
                              style={
                                this.state.tabIndex !== index
                                  ? {
                                      backgroundColor:
                                        config === "TCS"
                                          ? "rgb(229, 200, 139)"
                                          : "#fff",
                                      color: "#000",
                                    }
                                  : this.state.hasChosen
                                  ? {
                                      backgroundColor:
                                        config === "TCS"
                                          ? "#e6a44a"
                                          : "rgb(146, 112, 25)",
                                      color: config === "TCS" ? "#000" : "#fff",
                                    }
                                  : {
                                      backgroundColor:
                                        config === "TCS"
                                          ? "rgb(229, 200, 139)"
                                          : "#fff",
                                      color: "#000",
                                    }
                              }
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({
                                  tabIndex: index,
                                  hasChosen: true,
                                });
                              }}
                              className="range-button">
                              {moment(range.start).format("hA")} to{" "}
                              {moment(range.end).format("hA")}
                            </button>
                          );
                        }
                      )}
                    </div>
                    {this.state.timingsToUse.filterRanges.map(
                      (range, rangeIndex) => {
                        return (
                          <div
                            style={
                              this.state.tabIndex === rangeIndex &&
                              this.state.hasChosen
                                ? {}
                                : { display: "none" }
                            }
                            className="timing-buttons">
                            {this.filterResults(rangeIndex).map((timing) => {
                              if (
                                this.state.timingsToUse.blockedRanges.findIndex(
                                  (item) => item.label === timing.label
                                ) !== -1
                              ) {
                                return null;
                              } else {
                                return (
                                  <button
                                    style={
                                      timing.timing.format("HH:mm") ===
                                      this.state.time.format("HH:mm")
                                        ? {
                                            backgroundColor:
                                              config === "TCS"
                                                ? "#e6a44a"
                                                : "rgb(146, 112, 25)",
                                            color:
                                              config === "TCS"
                                                ? "#000"
                                                : "#fff",
                                          }
                                        : {
                                            backgroundColor:
                                              config === "TCS"
                                                ? "rgb(229, 200, 139)"
                                                : "#000",
                                            color:
                                              config === "TCS"
                                                ? "#000"
                                                : "#fff",
                                          }
                                    }
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.setState({
                                        time: timing.timing,
                                        timeChosen: true,
                                      });
                                    }}
                                    className="timing-button">
                                    {timing.label}
                                  </button>
                                );
                              }
                            })}
                          </div>
                        );
                      }
                    )}
                    {/* <div className="timing-buttons">
                    {this.generateSlots(mockData.availableRanges[0]).map(
                      (timing) => {
                        return (
                          <button className="timing-button">
                            {timing.label}
                          </button>
                        );
                      }
                    )}
                  </div> */}
                  </div>
                ) : null}
                {/*    <TimePicker
                  hideDisabledOptions
                  defaultValue={this.state.time}
                  showSecond={false}
                  onChange={this.onTimeChange}
                  allowEmpty={false}
                  minuteStep={15}
                  disabledHours={() => this.state.hours}
                  disabledMinutes={() =>
                    this.disabledMinutes(this.state.hour)
                  }></TimePicker> */}
              </div>
            ) : null}
          </div>

          <div className="form-group">
            <label
              style={
                this.state.checked && this.state.name == ""
                  ? { color: "red" }
                  : null
              }>
              Name*
            </label>
            <input
              required
              value={this.state.name}
              onChange={this.onChange}
              id="name"
              type="text"></input>
          </div>
          <div className="form-group" id="mobile-no">
            <label
              style={
                this.state.checked && this.state.mobile == ""
                  ? { color: "red" }
                  : null
              }>
              Mobile No*
            </label>
            <input
              required
              value={this.state.mobile}
              onChange={this.onChange}
              id="mobile"
              type="number"></input>
          </div>
          <div className="form-group">
            <label
              style={
                this.state.checked && this.state.email == ""
                  ? { color: "red" }
                  : null
              }>
              Email*
            </label>
            <input
              required
              value={this.state.email}
              onChange={this.onChange}
              id="email"
              type="Email"
              pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"></input>
          </div>

          <div className="form-group">
            <label
              style={
                this.state.checked && this.state.pax == ""
                  ? { color: "red" }
                  : null
              }>
              No of Pax*
            </label>
            <input
              required
              min="1"
              value={this.state.pax}
              onChange={this.onChange}
              type="number"
              id="pax"></input>
          </div>

          <div className="form-group">
            <label
              className="label-with"
              style={
                this.state.checked && this.state.seatPref == ""
                  ? { color: "red" }
                  : null
              }>
              Seat Preference*
            </label>

            <div className="radio-div">
              <input
                checked={this.state.seatPref === "indoor"}
                required
                value="indoor"
                onChange={this.onChange}
                id="seatPref"
                name="seatPref"
                type="radio"></input>
              <span>Indoor</span>
            </div>
            <div className="radio-div">
              <input
                checked={this.state.seatPref === "outdoor"}
                required
                value="outdoor"
                onChange={this.onChange}
                id="seatPref"
                name="seatPref"
                type="radio"></input>
              <span>Outdoor</span>
            </div>
            <div className="radio-div">
              <input
                checked={this.state.seatPref === "Bar"}
                required
                value="Bar"
                onChange={this.onChange}
                id="seatPref"
                name="seatPref"
                type="radio"></input>
              <span>Bar</span>
            </div>
          </div>
          {config === "TCS" ? (
            <div className="form-group">
              <label
                className="label-without"
                style={
                  this.state.checked && this.state.ht == ""
                    ? { color: "red" }
                    : null
                }>
                Are you joining us for High Tea?
              </label>
              <div className="radio-div">
                <input
                  checked={this.state.seatPref === "Yes"}
                  required
                  value="Yes"
                  onChange={this.onChange}
                  id="ht"
                  name="ht"
                  type="radio"></input>
                <span>Yes</span>
              </div>
              <div className="radio-div">
                <input
                  checked={this.state.seatPref === "No"}
                  required
                  value="No"
                  onChange={this.onChange}
                  id="ht"
                  name="ht"
                  type="radio"></input>
                <span>No</span>
              </div>
            </div>
          ) : (
            <div className="form-group">
              <label
                style={
                  this.state.checked && this.state.contactPref == ""
                    ? { color: "red" }
                    : null
                }>
                Contact Preference*
              </label>

              <div className="radio-div">
                <input
                  required
                  value="mobile"
                  onChange={this.onChange}
                  id="contactPref"
                  name="contactPref"
                  type="radio"></input>
                <span>Mobile</span>
              </div>
              <div className="radio-div">
                <input
                  required
                  value="Email"
                  onChange={this.onChange}
                  id="contactPref"
                  name="contactPref"
                  type="radio"></input>
                <span>Email</span>
              </div>
            </div>
          )}

          <div className="form-group full-width">
            <label>Special Requests</label>
            <textarea
              value={this.state.remarks}
              onChange={this.onChange}
              id="remarks"></textarea>
          </div>

          <button
            className="full-width"
            type="submit"
            onClick={this.onSubmit}
            disabled={this.state.disabled}>
            {this.state.loading}
          </button>
        </form>
        <div id="note">
          <span>
            <br />
            <ul className="bullet">
              {this.state.notices.map((notice) => {
                return <li>{notice}</li>;
              })}
              {/* <li>
                As of 29 March 2022, we will be accepting reservations for group
                size of 10 pax.
              </li>
              <li>
                As we are trying our best to accommodate to all guests with the
                reduced seating capacity, we seek your understanding that all
                reservations are for <b>2 hour</b> seatings only.
              </li>
              <li>
                If your reservation is for today, call us instead - 6475 0105.
              </li>
              <li>
                {" "}
                A grace period of 15 min will be given from your reservation
                time.
              </li> */}
            </ul>
          </span>
        </div>
      </div>
    );
  }
}

export default withRouter(EditNew);
