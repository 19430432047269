import React, { Component } from "react";
import { Check, X } from "react-feather";
import { Modal } from "flwww";
import { db } from "../Firebase/firebase";
import { AppContext } from "../context/AppContext";
export default class RowAction extends Component {
  static contextType = AppContext;
  state = {
    visible: false,
    visible1: false,
    reply: "",
  };
  toggleModal = () => {
    this.setState({
      visible: !this.state.visible,
    });
  };

  toggleModal1 = () => {
    this.setState({
      visible1: !this.state.visible1,
    });
  };

  confirmDecline = () => {
    db.collection("reservations").doc(this.props.data.id).update({
      declined: true,
      acknowledgement: new Date(),
    });
  };

  confirmReserve = () => {
    db.collection("reservations").doc(this.props.data.id).update({
      confirmed: true,
      staffReply: this.state.reply,
      acknowledgement: new Date(),
    });
  };
  cancelReservation = () => {
    this.setState(
      {
        sending: "Sending...",
      },
      () => {
        const mailData = {
          name: this.props.data.name,
          date: new Date(this.props.data.date.seconds * 1000),
          pax: this.props.data.pax,
          seatPref: this.props.data.seatPref,
          time: this.props.data.time,
          email: this.props.data.email,
          outlet: this.context.outlet,
        };
        fetch(
          "https://us-central1-reservations-7dd65.cloudfunctions.net/widgets/reject",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(mailData),
          }
        )
          .then((res) => res.json())
          .then((data) => {
            if (data.message) {
              console.log("Success");
              this.confirmDecline();
              this.toggleModal();
            }
          });
      }
    );
  };

  sendConfirmation = () => {
    const mailData = {
      name: this.props.data.name,
      date: new Date(this.props.data.date.seconds * 1000),
      pax: this.props.data.pax,
      seatPref: this.props.data.seatPref,
      time: this.props.data.time,
      email: this.props.data.email,
      remarks: this.props.data.remarks,
      reply: this.state.reply,
      id: this.props.data.id,
      outlet: this.context.outlet,
    };
    fetch(
      "https://us-central1-reservations-7dd65.cloudfunctions.net/widgets/sendConfirmationMail",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(mailData),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.message) {
          console.log("Success");
          this.confirmReserve();
          this.toggleModal1();
        } else {
          console.log("failed");
        }
      });
  };
  render() {
    return (
      <React.Fragment>
        <Modal
          centered
          isVisible={this.state.visible}
          toggleModal={this.toggleModal}>
          <div className="confirm-body">
            <h3>Confirm Decline?</h3>

            <div className="confirm-buttons">
              <button
                style={{ backgroundColor: "#3ee67e" }}
                onClick={this.cancelReservation}>
                Confirm
              </button>
              <button
                style={{ backgroundColor: "#f71b39" }}
                onClick={this.toggleModal}>
                Cancel
              </button>
            </div>
          </div>
        </Modal>

        <Modal
          centered
          isVisible={this.state.visible1}
          toggleModal={this.toggleModal1}>
          <div className="confirm-body">
            <h3>Reply to guest’s special requests (if any):</h3>
            <div className="form-group full-width" style={{ width: "100%" }}>
              <textarea
                style={{ width: "100%", height: 240 }}
                value={this.state.reply}
                onChange={this.onChange}
                id="reply"></textarea>
            </div>
            <div className="confirm-buttons">
              <button
                style={{ backgroundColor: "#51975B" }}
                onClick={this.sendConfirmation}>
                Confirm
              </button>
              <button
                style={{ backgroundColor: "#C0564B" }}
                onClick={this.toggleModal1}>
                Cancel
              </button>
            </div>
          </div>
        </Modal>
        <div onClick={this.toggleModal1} className="tableNumber row-accept">
          <Check></Check>
        </div>
        <div onClick={this.toggleModal} className="tableNumber2 row-decline">
          <X></X>
        </div>
      </React.Fragment>
    );
  }
}
