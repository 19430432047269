import React, { Component } from "react";
import Dashboard from "./dashboard/Dashboard";
import Confirmation from "./create/Confirmation";
import Today from "./date/Today";
import Floor from "./operations/Floor";
import History from "./dashboard/History";
import { Route } from "react-router-dom";
import { NavBar } from "./NavBar";
import Ops from "./operations/Ops";
import { AppContext } from "./context/AppContext";
import { Helmet } from "react-helmet";
import Admin from "./admin/Admin";
import { Drawer } from "flwww";
import EditNew from "./create/EditNew";
import { IoMdMenu } from "react-icons/io";

export default class Outlet extends Component {
  static contextType = AppContext;
  state = {
    drawerIsVisible: false,
  };
  toggleDrawer = () => {
    this.setState((prevState) => ({
      drawerIsVisible: !prevState.drawerIsVisible,
    }));
  };
  render() {
    let { outlet, isMobile } = this.context;
    return (
      <div className="outlet">
        {this.context.isMobile ? (
          <button className="drawer-btn" onClick={this.toggleDrawer}>
            <IoMdMenu style={{ height: "2em", width: "2em" }}></IoMdMenu>
          </button>
        ) : null}
        <Helmet>
          <title>{this.context.outlet}</title>
        </Helmet>
        {outlet === "TCS" ? (
          <img
            className="outlet-logo"
            src={require(`../images/${outlet}.png`)}></img>
        ) : (
          <img id="inverse-logo" src={require(`../images/${outlet}.png`)}></img>
        )}
        {/* <span id="version">4.2b</span> */}
        {isMobile ? (
          <Drawer
            style={{
              backgroundColor:
                this.context.outlet === "TCS" ? "#e5c88b" : "rgb(24, 24, 24)",
            }}
            showDrawer={this.state.drawerIsVisible}
            toggleDrawer={this.toggleDrawer}>
            <NavBar></NavBar>
          </Drawer>
        ) : (
          <NavBar></NavBar>
        )}
        <Route
          path={`/main/dashboard`}
          render={(props) => (
            <Dashboard {...props} setCount={() => this.setCount} />
          )}
        />
        <Route
          path={`/main/edit/:reserve`}
          render={(props) => <EditNew {...props} />}
        />
        <Route path={`/main/today`} render={(props) => <Today {...props} />} />
        <Route
          path={`/main/history`}
          render={(props) => <History {...props} />}
        />
        <Route path={`/main/floor`} render={(props) => <Floor {...props} />} />
        <Route path={`/main/ops`} render={(props) => <Ops {...props} />} />
        <Route path={`/main/admin`} render={(props) => <Admin {...props} />} />
        <Route
          path={`/main/confirmation`}
          render={(props) => <Confirmation {...props} />}
        />
      </div>
    );
  }
}
