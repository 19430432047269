import React, { Component } from "react";
import { Route, withRouter, Redirect } from "react-router-dom";
import "./styles/main.css";
import { auth } from "./components/Firebase/firebase";
import Login from "./components/Login";
import PrivateRoute from "./components/PrivateRoute";
import Main from "./components/Main";
import Confirmation from "./components/create/Confirmation";
import { AppContext } from './components/context/AppContext';

class App extends Component {
  static contextType = AppContext;
  state = {
    auth: true,
    user: null,
  };
  componentDidMount() {
    /*auth
      .createUserWithEmailAndPassword("andreweijie@gmail.com", "Many@1234")
      .catch(function(error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // ...
        console.log(errorMessage);
      });*/
    
    auth.onAuthStateChanged((user) => {
      if (user) {
        user.getIdTokenResult().then(idTokenResult => {
          if(idTokenResult.claims.masterAdmin) {
            this.context.setMasterAdmin(true);
          }
          else {
            this.context.setMasterAdmin(false);
          }
          if (idTokenResult.claims.admin) {
            this.context.setAdmin(true);
          }
          else {
            this.context.setAdmin(false);
          }
          if (idTokenResult.claims.isViewer) {
            this.context.setViewer(true);
          }
          else {
            this.context.setViewer(false);
          }
          
        })
        this.setState({ user, auth: true });
        if (this.context.view) {
          this.props.history.replace("/main/floor");
        }
        else {
          this.props.history.replace("/main/dashboard");
        }
      } else {
        this.setState({ auth: false });
      }
    });
  }

  render() {
    return (
      <div className="App">
        {this.state.auth ? <button id="logout-button" onClick={() => auth.signOut()}>logout</button> : null}
        <Route exact path="/" render={() => <Redirect to="/main/TCS" />} />
        <Route exact path="/login" render={(props) => <Login {...props} />} />
        <PrivateRoute
          path="/main"
          component={Main}
          auth={this.state.auth}
        ></PrivateRoute>
        <Route exact path="/confirmation" component={Confirmation}></Route>
      </div>
    );
  }
}

export default withRouter(App);
