import React, { Component } from "react";
import { db } from "../Firebase/firebase";
import { Checkbox } from "flwww";
import { Popover } from "antd";
import SelectTable from "./SelectTable";
import "antd/dist/antd.css";

import RowAction from "./RowAction";

export default class TableRow extends Component {
  state = {
    areas: [],
    popOpen1: false,
    popOpen2: false,
  };

  componentDidMount() {
    db.collection("tables")
      .doc("TCS")
      .onSnapshot((doc) => {
        this.setState({ areas: doc.data().areas });
      });
  }

  handleVisibleChange1 = (popOpen1) => {
    this.setState({ popOpen1 });
  };

  handleVisibleChange2 = (popOpen2) => {
    this.setState({ popOpen2 });
  };

  hide1 = () => {
    this.setState({
      popOpen1: false,
    });
  };

  hide2 = () => {
    this.setState({
      popOpen2: false,
    });
  };

  onTableChange = (property, number) => {
    console.log("changed");
    db.collection("reservations")
      .doc(this.props.data.id)
      .update({ [property]: number });
  };
  confirmArrival = () => {
    db.collection("reservations").doc(this.props.data.id).update({
      finished: !this.props.data.finished,
    });
  };
  handleCheck = () => {
    db.collection("reservations").doc(this.props.data.id).update({
      turnedOver: !this.props.data.turnedOver,
    });
  };
  render() {
    return (
      <div
        className={
          this.props.data.cancelled
            ? "floor-table-row cancelled"
            : "floor-table-row"
        }
        style={
          this.props.data.finished
            ? { backgroundColor: "#3ee67e", color: "#000" }
            : this.props.data.declined
            ? { backgroundColor: "#f71b39" }
            : this.props.current > this.props.data.dateTime
            ? { backgroundColor: "orange", color: "#000" }
            : null
        }>
        <span>{this.props.data.time}</span>
        <span className="table-row-pax">{this.props.data.pax}</span>
        <span
          className="table-row-name"
          style={
            this.props.data.ht === "Yes"
              ? { fontWeight: "bold", color: "red" }
              : {}
          }
          onClick={this.confirmArrival}>
          {this.props.data.name}
        </span>
        <span className="table-row-mobile">{this.props.data.mobile}</span>

        <span className="table-row-seat">{this.props.data.seatPref}</span>
        <span className="turn-over">
          <Checkbox
            name="checkbox"
            onChange={this.handleCheck}
            checked={this.props.data.turnedOver}></Checkbox>
        </span>
        <span className="table-number">
          {/* <select
            name="tableNumber"
            id="tableNumber"
            onChange={this.onTableChange}>
            {this.state.areas.map((area) => {
              return area.tableList.map((table) => {
                return (
                  <option
                    selected={table === this.props.data.tableNo}
                    value={table}>
                    {table}
                  </option>
                );
              });
            })}
            <option selected={!this.props.data.tableNo} value={""}>
              {""}
            </option>
          </select> */}
          {this.props.data.confirmed ? (
            <React.Fragment>
              <div className="tableNumber">
                <Popover
                  placement="bottomRight"
                  content={
                    <SelectTable
                      close={this.hide1}
                      changeTable={this.onTableChange}
                      whichTable={"tableNo"}></SelectTable>
                  }
                  trigger="click"
                  onVisibleChange={this.handleVisibleChange1}
                  visible={this.state.popOpen1}>
                  {this.props.data.tableNo || "NIL"}
                </Popover>
              </div>
              <div className="tableNumber2">
                <Popover
                  placement="bottomRight"
                  content={
                    <SelectTable
                      close={this.hide2}
                      changeTable={this.onTableChange}
                      whichTable={"tableNo2"}></SelectTable>
                  }
                  trigger="click"
                  onVisibleChange={this.handleVisibleChange2}
                  visible={this.state.popOpen2}>
                  {this.props.data.tableNo2 || "NIL"}
                </Popover>
              </div>
            </React.Fragment>
          ) : (
            <RowAction data={this.props.data}></RowAction>
          )}
          {/* <input
            type="text"
            onChange={this.onTableChange}
            value={this.props.data.tableNo}></input> */}
        </span>
        {this.props.data.remarks !== "" ? (
          <span className="table-remarks">{this.props.data.remarks}</span>
        ) : null}
      </div>
    );
  }
}
