import React, { Component } from "react";
import { Checkbox } from "flwww";
import { TimePicker, Select } from "antd";
import { db } from "../Firebase/firebase";
import { AppContext } from "../context/AppContext";
import { message } from "flwww";
import moment from "moment";

const { Option } = Select;
export default class TimingControls extends Component {
  static contextType = AppContext;
  state = {
    docId: "",
    disableDay: false,
    newDoc: false,
    defaultWeekDay: { filterRanges: [] },
    defaultWeekEnd: { filterRanges: [] },
    defaultFriday: { filterRanges: [] },
    start: moment(),
    end: moment().add(60, "m"),
    timeStep: 15,
    filterRanges: [],
    blockedRanges: [],
    ignoreDefault: false,
    newFilterStart: "",
    newFilterEnd: "",
  };

  componentDidMount() {
    let disabledDayDoc =
      this.context.outlet === "TCS"
        ? "UE7VmGfyNhBMjm1LIHDu"
        : "bFNcmIQkj6xENfA5Cdpi";

    let docRef = db.collection("disableddays").doc(disabledDayDoc);

    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          let list = doc.data().date;
          let dateIndex = list.findIndex(
            (item) =>
              item.toDate().toDateString() === this.props.date.toDateString()
          );
          this.setState({ disableDay: dateIndex === -1 ? false : true });
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });

    let { defaultSelected, date, type } = this.props;
    let weekDay =
      this.context.outlet === "TCS"
        ? "defaultTCSWeekDay"
        : "defaultWeekDayCMPB";

    let weekEnd =
      this.context.outlet === "TCS"
        ? "defaultTCSWeekEnd"
        : "defaultWeekEndCMPB";

    let friday =
      this.context.outlet === "TCS" ? "defaultFridayTCS" : "defaultFridayCMPB";

    db.collection("timings")
      .doc(weekDay)
      .onSnapshot((doc) => {
        let data = doc.data();
        data.start = moment(data.start.toDate());
        data.end = moment(data.end.toDate());
        data.blockedRanges = data.blockedRanges.map((range) => {
          return { timing: moment(range.timing.toDate()), label: range.label };
        });
        data.filterRanges = data.filterRanges.map((range) => {
          return {
            start: moment(range.start.toDate()),
            end: moment(range.end.toDate()),
          };
        });
        if (defaultSelected && type === "weekday") {
          this.setState(data);
        } else if (type === "weekday") {
          this.setState({
            start: data.start,
            end: data.end,
            filterRanges: data.filterRanges,
            timeStep: data.timeStep,
          });
        } else {
          this.setState({
            defaultWeekDay: data,
          });
        }
      });

    db.collection("timings")
      .doc(friday)
      .onSnapshot((doc) => {
        let data = doc.data();
        data.start = moment(data.start.toDate());
        data.end = moment(data.end.toDate());
        data.blockedRanges = data.blockedRanges.map((range) => {
          return {
            timing: moment(range.timing.toDate()),
            label: range.label,
          };
        });
        data.filterRanges = data.filterRanges.map((range) => {
          return {
            start: moment(range.start.toDate()),
            end: moment(range.end.toDate()),
          };
        });
        if (defaultSelected && type === "friday") {
          this.setState(data);
        } else if (type === "friday") {
          this.setState({
            start: data.start,
            end: data.end,
            filterRanges: data.filterRanges,
            timeStep: data.timeStep,
          });
        } else {
          this.setState({
            defaultFriday: data,
          });
        }
      });

    db.collection("timings")
      .doc(weekEnd)
      .onSnapshot((doc) => {
        let data = doc.data();
        data.start = moment(data.start.toDate());
        data.end = moment(data.end.toDate());
        data.blockedRanges = data.blockedRanges.map((range) => {
          return { timing: moment(range.timing.toDate()), label: range.label };
        });
        data.filterRanges = data.filterRanges.map((range) => {
          return {
            start: moment(range.start.toDate()),
            end: moment(range.end.toDate()),
          };
        });
        if (defaultSelected && type === "weekend") {
          this.setState(data);
        } else if (type === "weekend") {
          this.setState({
            start: data.start,
            end: data.end,
            filterRanges: data.filterRanges,
            timeStep: data.timeStep,
          });
        } else {
          this.setState({
            defaultWeekEnd: data,
          });
        }
      });

    if (!defaultSelected) {
      let day = date.getDate();
      let month = date.getMonth();
      let year = date.getFullYear();
      db.collection("timings")
        .where("outlet", "==", this.context.outlet)
        .where("date", ">=", new Date(year, month, day, 0, 0, 0, 0))
        .where("date", "<=", new Date(year, month, day, 23, 0, 0, 0))
        .onSnapshot((snapshot) => {
          if (snapshot.empty) {
            this.setState({
              newDoc: snapshot.empty,
            });
          } else {
            let dateInfo = {};
            snapshot.forEach((doc) => {
              dateInfo = doc.data();
              if (dateInfo.ignoreDefault) {
                dateInfo.start = moment(dateInfo.start.toDate());
                dateInfo.end = moment(dateInfo.end.toDate());
                dateInfo.filterRanges = dateInfo.filterRanges.map((range) => {
                  return {
                    start: moment(range.start.toDate()),
                    end: moment(range.end.toDate()),
                  };
                });
              }
              dateInfo.blockedRanges = dateInfo.blockedRanges.map((range) => {
                return {
                  timing: moment(range.timing.toDate()),
                  label: range.label,
                };
              });

              dateInfo.docId = doc.id;
            });
            this.setState({
              newDoc: false,
              ...dateInfo,
            });
          }
        });
    } else {
      console.log("tes");
      if (type == "weekday") {
        console.log("trying");
      } else {
      }
    }
  }

  onTimeChange = (time, cat) => {
    this.setState({
      [cat]: time,
    });
  };

  onSelectChange = (value) => {
    this.setState({
      timeStep: value,
      blockedRanges: [],
    });
  };

  handleDisableCheck = () => {
    this.setState({ disableDay: !this.state.disableDay }, () => {
      let disabledDayDoc =
        this.context.outlet === "TCS"
          ? "UE7VmGfyNhBMjm1LIHDu"
          : "bFNcmIQkj6xENfA5Cdpi";

      let docRef = db.collection("disableddays").doc(disabledDayDoc);

      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            let list = doc.data().date;
            if (this.state.disableDay) {
              list.push(this.props.date);
            } else {
              let dateIndex = list.findIndex(
                (item) =>
                  item.toDate().toDateString() ===
                  this.props.date.toDateString()
              );
              list.splice(dateIndex, 1);
            }

            docRef.update({
              date: list,
            });
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
    });
  };

  handleDefaultsCheck = () => {
    this.setState({ ignoreDefault: !this.state.ignoreDefault });
  };

  addFilter = () => {
    if (this.state.newFilterStart === "" || this.state.newFilterEnd === "") {
      return;
    }
    let filterRangesCopy = [...this.state.filterRanges];
    filterRangesCopy.push({
      start: this.state.newFilterStart,
      end: this.state.newFilterEnd,
    });
    this.setState({
      filterRanges: filterRangesCopy,
      newFilterStart: "",
      newFilterEnd: "",
    });
  };

  deleteRange = (rangeIndex) => {
    let filterRangesCopy = [...this.state.filterRanges];
    filterRangesCopy.splice(rangeIndex, 1);
    this.setState({ filterRanges: filterRangesCopy });
  };

  generateSlots = (availableRange) => {
    let { timeStep } = this.state;
    let slots = [];
    let currentSlot = {
      timing: moment(availableRange.start),
      label: `${moment(availableRange.start).format("HH:mm")}`,
    };
    while (
      currentSlot.timing.hour() * 100 + currentSlot.timing.minute() <=
      moment(availableRange.end).hour() * 100 +
        moment(availableRange.end).minute()
    ) {
      let newSlot = { ...currentSlot };
      slots.push(newSlot);
      let nextSlot = { ...newSlot };
      nextSlot.timing = nextSlot.timing.clone().add(timeStep, "m");
      nextSlot.label = `${nextSlot.timing.format("HH:mm")}`;
      currentSlot = nextSlot;
    }
    return slots;
  };

  saveChanges = () => {
    let blockedCopy;
    let filterCopy;

    blockedCopy = this.state.blockedRanges.map((range) => {
      return { timing: range.timing.toDate(), label: range.label };
    });
    filterCopy = this.state.filterRanges.map((range) => {
      return {
        start: range.start.toDate(),
        end: range.end.toDate(),
      };
    });

    if (this.props.defaultSelected) {
      let weekDay =
        this.context.outlet === "TCS"
          ? "defaultTCSWeekDay"
          : "defaultWeekDayCMPB";

      let weekEnd =
        this.context.outlet === "TCS"
          ? "defaultTCSWeekEnd"
          : "defaultWeekEndCMPB";

      let friday =
        this.context.outlet === "TCS"
          ? "defaultFridayTCS"
          : "defaultFridayCMPB";

      let defaultDoc =
        this.props.type === "weekday"
          ? weekDay
          : this.props.type === "friday"
          ? friday
          : weekEnd;

      db.collection("timings").doc(defaultDoc).update({
        start: this.state.start.toDate(),
        end: this.state.end.toDate(),
        timeStep: this.state.timeStep,
        filterRanges: filterCopy,
        blockedRanges: blockedCopy,
      });
      message("Changes Saved!", "success", 3);
    } else {
      let docData = this.state.ignoreDefault
        ? {
            start: this.state.start.toDate(),
            end: this.state.end.toDate(),
            timeStep: this.state.timeStep,
            filterRanges: filterCopy,
            blockedRanges: blockedCopy,
            outlet: this.context.outlet,
            ignoreDefault: this.state.ignoreDefault,
            date: this.props.date,
          }
        : {
            date: this.props.date,
            blockedRanges: blockedCopy,
            outlet: this.context.outlet,
            ignoreDefault: this.state.ignoreDefault,
          };
      if (this.state.newDoc) {
        db.collection("timings")
          .add(docData)
          .then((docRef) => {
            this.setState({ newDoc: false, docId: docRef.id });
            message("Changes Saved!", "success", 3);
          })
          .catch((error) => {
            console.error("Error adding document: ", error);
          });
      } else {
        db.collection("timings").doc(this.state.docId).update(docData);
        message("Changes Saved!", "success", 3);
      }
    }
  };

  render() {
    let { start, end, blockedRanges, filterRanges, timeStep, ignoreDefault } =
      this.state;
    let { defaultSelected, type } = this.props;
    console.log(this.state.docId);
    /* console.log(this.generateSlots({ start, end })); */

    return (
      <div className="timing-controls-box">
        {!this.props.defaultSelected ? (
          <React.Fragment>
            <div className="disable-day">
              <Checkbox
                onChange={this.handleDisableCheck}
                checked={this.state.disableDay}></Checkbox>
              <label htmlFor="">Disable Entire Day</label>
            </div>
            <div className="disable-day">
              <Checkbox
                onChange={this.handleDefaultsCheck}
                checked={this.state.ignoreDefault}></Checkbox>
              <label htmlFor="">Ignore Defaults</label>
            </div>
          </React.Fragment>
        ) : null}
        <br />
        {ignoreDefault || defaultSelected ? (
          <React.Fragment>
            <div className="range-box">
              <div className="range-input">
                <label htmlFor="">Start Time</label>
                <TimePicker
                  allowClear={false}
                  format="HH:mm"
                  onChange={(time, timeString) =>
                    this.onTimeChange(time, "start")
                  }
                  value={moment(this.state.start)}></TimePicker>
              </div>
              <div className="range-input">
                <label htmlFor="">End Time</label>
                <TimePicker
                  allowClear={false}
                  format="HH:mm"
                  onChange={(time, timeString) =>
                    this.onTimeChange(time, "end")
                  }
                  value={moment(this.state.end)}></TimePicker>
              </div>
              <div className="timeslot-duration">
                <label htmlFor="">Timestep</label>
                <Select
                  value={this.state.timeStep}
                  onChange={this.onSelectChange}>
                  <Option value={15}>15</Option>
                  <Option value={30}>30</Option>
                  <Option value={60}>60</Option>
                </Select>
              </div>
            </div>
            <br></br>
            <div className="filters">
              <div className="filter-ranges">
                <div className="range-input">
                  <label htmlFor="">Start Time</label>
                  <TimePicker
                    allowClear={false}
                    format="HH:mm"
                    onChange={(time, timeString) =>
                      this.onTimeChange(time, "newFilterStart")
                    }
                    value={this.state.newFilterStart}></TimePicker>
                </div>
                <div className="range-input">
                  <label htmlFor="">End Time</label>
                  <TimePicker
                    allowClear={false}
                    format="HH:mm"
                    onChange={(time, timeString) =>
                      this.onTimeChange(time, "newFilterEnd")
                    }
                    value={this.state.newFilterEnd}></TimePicker>
                </div>
                <button onClick={this.addFilter}>Add Filter</button>
              </div>
              <div className="filter-list">
                {this.state.filterRanges.map((range, rangeIndex) => {
                  return (
                    <div className="filter-range">
                      {moment(range.start.toDate()).format("hh:mmA")} to{" "}
                      {moment(range.end.toDate()).format("hh:mmA")}
                      <button
                        className="corner-delete"
                        onClick={() => this.deleteRange(rangeIndex)}>
                        X
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          </React.Fragment>
        ) : null}
        <br />
        <div className="timing-buttons">
          {this.generateSlots({ start, end }).map((slot) => {
            return (
              <button
                style={
                  blockedRanges.findIndex(
                    (element) => element.label === slot.label
                  ) !== -1
                    ? { backgroundColor: "#4F0910", color: "#ff9da3" }
                    : {}
                }
                onClick={() => {
                  let slotIndex = blockedRanges.findIndex(
                    (element) => element.label === slot.label
                  );
                  if (slotIndex === -1) {
                    this.setState({
                      blockedRanges: [...blockedRanges, slot],
                    });
                  } else {
                    let newList = [...blockedRanges];
                    newList.splice(
                      newList.findIndex(
                        (element) => element.label === slot.label
                      ),
                      1
                    );
                    this.setState({
                      blockedRanges: newList,
                    });
                  }
                }}
                className="timing-btn">
                {slot.label}
              </button>
            );
          })}
        </div>
        <button className="save-btn" onClick={this.saveChanges}>
          Save Changes
        </button>
      </div>
    );
  }
}
