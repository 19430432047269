import React, { Component } from "react";
import { db, auth } from "../Firebase/firebase";
import { Dropdown, message } from "flwww";
import { Button, Modal } from "antd";
import { AppContext } from "../context/AppContext";

export default class Accounts extends Component {
  static contextType = AppContext;
  state = {
    users: [],
    createNew: false,
    currentUserID: "",
    currentUserEmail: "",
    newPassword: "",
    newAccEmail: "",
    newAccPassword: "",
    changePassword: false,
  };

  componentDidMount() {
    db.collection("users")
      .where("outlet", "array-contains", this.context.outlet)
      .onSnapshot((querySnapShot) => {
        let userData = [];
        querySnapShot.forEach((doc) => {
          let user = doc.data();
          userData.push({ ...user, id: doc.id });
        });
        this.setState({
          users: userData,
        });
      });
  }

  changeRole = (roleData) => {
    //
    fetch(
      "https://us-central1-reservations-7dd65.cloudfunctions.net/widgets/setRole",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: this.state.currentUserEmail,
          ...roleData,
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.message) {
          console.log(data.message);
          message("Changes Saved!", "success", 3);
        } else {
          console.log(data);
          console.log("Failed");
        }
      });
  };

  changePassword = () => {
    fetch(
      "https://us-central1-reservations-7dd65.cloudfunctions.net/widgets/changePassword",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: this.state.currentUserEmail,
          uid: this.state.currentUserID,
          newPassword: this.state.newPassword,
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.message) {
          console.log(data.message);
          message("Password Changed!", "success", 3);
          /* auth.currentUser.reload(); */
        } else {
          console.log(data);
          console.log("Failed");
        }
      });
  };

  createNewAccount = () => {
    fetch(
      "https://us-central1-reservations-7dd65.cloudfunctions.net/widgets/createUser",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: this.state.newAccEmail,
          password: this.state.newAccPassword,
          outlet: this.context.outlet,
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.message) {
          console.log(data.message);
          message("User Created!", "success", 3);
          this.setState({ createNew: false });
        } else {
          console.log(data);
          console.log("Failed");
        }
      });
  };

  deleteUser = () => {
    fetch(
      "https://us-central1-reservations-7dd65.cloudfunctions.net/widgets/deleteUser",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          uid: this.state.currentUserID,
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.message) {
          console.log(data.message);
          message("User Deleted!", "success", 3);
          db.collection("users")
            .doc(this.state.currentUserID)
            .delete()
            .then(() => {
              console.log("Document successfully deleted!");
            })
            .catch((error) => {
              console.error("Error removing document: ", error);
            });
        } else {
          console.log(data);
          console.log("Failed");
        }
      });
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  testSms = () => {
    fetch(
      "https://us-central1-reservations-7dd65.cloudfunctions.net/widgets/testSMS",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          mode: "no-cors",
        },
        body: JSON.stringify({
          ID: 131930002,
          Password: "Spiceoflife@1",
          Mobile: 6598522802,
          Type: "AUTO",
          Message: "testing",
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
      });
  };

  render() {
    let { users, createNew, changePassword } = this.state;
    const roleList = [
      <button
        className="dropdown-btn"
        onClick={() =>
          this.changeRole({
            isMasterAdmin: false,
            isAdmin: false,
            isViewOnly: false,
          })
        }>
        None
      </button>,
      <button
        className="dropdown-btn"
        onClick={() =>
          this.changeRole({
            isMasterAdmin: false,
            isAdmin: true,
            isViewOnly: false,
          })
        }>
        Admin
      </button>,
      <button
        className="dropdown-btn"
        onClick={() =>
          this.changeRole({
            isMasterAdmin: true,
            isAdmin: false,
            isViewOnly: false,
          })
        }>
        Master Admin
      </button>,
      <button
        className="dropdown-btn"
        onClick={() =>
          this.changeRole({
            isMasterAdmin: false,
            isAdmin: false,
            isViewOnly: true,
          })
        }>
        View Only
      </button>,
    ];

    const actionList = [
      <button
        className="dropdown-btn"
        onClick={() => this.setState({ changePassword: true })}>
        Change Password
      </button>,
      <button className="dropdown-btn" onClick={this.deleteUser}>
        Delete Account
      </button>,
    ];
    return (
      <div className="account-management">
        {/*                 <button onClick={this.testSms}>test</button> */}
        <div className="user-grid">
          {users.map((user) => {
            let currentRole = user.isMasterAdmin
              ? "Master Admin"
              : user.isAdmin
              ? "Admin"
              : user.isViewOnly
              ? "View Only"
              : "None";
            return (
              <React.Fragment>
                <span className="acc-email">{user.email}</span>
                <div className="role-list">
                  <Dropdown elementList={roleList}>
                    <button
                      type="primary"
                      className="role-btn"
                      onClick={() =>
                        this.setState({
                          currentUserID: user.id,
                          currentUserEmail: user.email,
                          changePassword: false,
                        })
                      }>
                      {currentRole}
                    </button>
                  </Dropdown>
                </div>
                <div className="acc-actions">
                  <Dropdown elementList={actionList}>
                    <button
                      type="primary"
                      className="action-btn"
                      onClick={() =>
                        this.setState({
                          currentUserID: user.id,
                          currentUserEmail: user.email,
                          newPassword: "",
                          changePassword: false,
                        })
                      }>
                      <i class="im im-menu-dot-v"></i>
                      menu
                    </button>
                  </Dropdown>
                </div>
              </React.Fragment>
            );
          })}
        </div>
        <hr />
        <button
          className="form-btns"
          onClick={() => this.setState({ createNew: true })}>
          Create New
        </button>

        <Modal
          title="Create New Account"
          style={{ top: 20 }}
          centered
          visible={createNew}
          onOk={() => this.setState({ createNew: false })}
          onCancel={() => this.setState({ createNew: false })}
          footer={[
            <button
              className="form-btns"
              onClick={() => this.setState({ createNew: false })}>
              Cancel
            </button>,
            <button
              className="confirm-btn form-btns"
              onClick={this.createNewAccount}>
              Confirm
            </button>,
          ]}>
          <div className="accounts-form-group">
            <label htmlFor="">Email</label>
            <input
              autoComplete="off"
              type="email"
              name="newAccEmail"
              id="newAccEmail"
              value={this.state.newAccEmail}
              onChange={this.onChange}
            />
          </div>
          <div className="accounts-form-group">
            <label htmlFor="">Password</label>
            <input
              autoComplete="off"
              type="password"
              name="newAccPassword"
              id="newAccPassword"
              value={this.state.newAccPassword}
              onChange={this.onChange}
            />
          </div>
        </Modal>
        <Modal
          title="Change Password"
          style={{ top: 20 }}
          centered
          visible={changePassword}
          onOk={() => this.setState({ changePassword: false })}
          onCancel={() => this.setState({ changePassword: false })}
          footer={[
            <button
              className="form-btns"
              onClick={() => this.setState({ changePassword: false })}>
              Cancel
            </button>,
            <button
              className="confirm-btn form-btns"
              onClick={this.changePassword}>
              Confirm
            </button>,
          ]}>
          <div className="accounts-form-group">
            <label htmlFor="">New Password</label>
            <input
              autocomplete="off"
              type="password"
              name="newPassword"
              id="newPassword"
              value={this.state.newPassword}
              onChange={this.onChange}
            />
          </div>
        </Modal>
      </div>
    );
  }
}
